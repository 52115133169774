<template>
  <div>
    <NavTitle class="mb16" :title="title"/>
    <div class="config-list">
      <el-row :gutter="20">
        <el-col :span="12" class="mb16" v-for="(item, index) in navButtonList" :key="index">
          <div class="config-item">
            <div class="flex items-center">
              <LoadImage class="img-icon" :src="item.pic"></LoadImage>
              <div>
                <div class="title">{{item.text}}</div>
                <div class="desc">金刚区{{index + 1}}</div>
              </div>
            </div>
            <div class="operate-row">
              <div class="btn-operate" @click="handleDelete(index)">
                <img src="@/assets/images/icon-delete.png">
                <span>删除</span>
              </div>
              <div class="btn-operate" @click="toEdit(index)">
                <img src="@/assets/images/icon-edit.png">
                <span>编辑</span>
              </div>
              <div class="btn-operate" @click="movePre(index)">
                <img style="transform: rotate(180deg);" src="@/assets/images/next.png">
                <span>前移</span>
              </div>
              <div class="btn-operate" @click="moveNext(index)">
                <img src="@/assets/images/next.png">
                <span>后移</span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="12" class="pointer">
          <div class="btn-add" @click="toAdd()">
            <img src="@/assets/images/add-fill.png">
            <div>添加</div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import LoadImage from '@/components/LoadImage'
import { resItemsListApi, resItemsManageApi } from '@/api/resConf.js'
export default {
  name: 'NavButtonList',
  components: {
    NavTitle,
    LoadImage
  },
  data() {
    return {
      resType: this.$route.query.resType,
      confId: this.$route.query.confId,
      title: this.$route.query.title || '',
      navButtonList: []
    }
  },
  created() {
    this.getNavButtonList()
  },
  methods: {
    getNavButtonList() {
      const formData = {
        confId: this.confId,
        grayConfig: this.$store.state.isResourceGray,
        resType: this.resType
      }
      console.log('formData', formData)
      resItemsListApi(formData).then(res => {
        console.log('配置详情列表', res)
        if(this.resType == 'HOME_TYPE_TAB' || this.resType == 'FIND_TYPE_TAB') {
          this.title = '分栏金刚区'
          const columnIndex = this.$route.query.columnIndex || 0
          const tabs = res.data.tabs || []
          if(tabs.length > 0) {
            this.navButtonList = res.data.tabs[columnIndex]?.buttons || []
          }
        } else {
          this.navButtonList = res.data.navigates || []
        }
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    toAdd() {
      this.$router.push({
        path: '/resource/posterEdit',
        query: {
          resType: this.resType,
          confId: this.confId
        }
      })
    },
    toEdit(index) {
      const queryData = {
        resType: this.resType,
        confId: this.confId,
        isEdit: true,
        editIndex: index
      }
      if(this.resType == 'HOME_TYPE_TAB' || this.resType == 'FIND_TYPE_TAB') {
        Object.assign(queryData, {
          columnIndex: this.$route.query.columnIndex || 0
        })
      }
      this.$router.push({
        path: '/resource/posterEdit',
        query: queryData
      })
    },
    handleDelete(index) {
      this.$confirm('确认删除该金刚区吗？').then(action => {
        if(action == 'confirm') {
          this.navButtonList.splice(index, 1)
          this.saveConfig()
        }
      })
    },
    movePre(index) {
      if(index <= 0) return
      const preItem = this.navButtonList[index - 1]
      this.$set(this.navButtonList, index - 1, this.navButtonList[index])
      this.$set(this.navButtonList, index, preItem)
      this.saveConfig()
    },
    moveNext(index) {
      if(index == this.navButtonList.length - 1) return
      const nextItem = this.navButtonList[index + 1]
      this.$set(this.navButtonList, index + 1, this.navButtonList[index])
      this.$set(this.navButtonList, index, nextItem)
      this.saveConfig()
    },
    saveConfig() {
      const formData = {
        confId: this.confId,
        grayConfig: this.$store.state.isResourceGray,
        resType: this.resType,
        navigates: this.navButtonList
      }
      console.log('formData', formData)
      resItemsManageApi(formData).then(res => {
        this.$tips({message: res.msg, type: 'success'})
        this.getNavButtonList()
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.config-list {
  padding: 0 16px;
}
.config-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 4px;
  height: 90px;
  padding: 0 24px;
  .img-icon {
    width: 44px;
    height: 44px;
    margin-right: 8px;
    border-radius: 4px;
  }
  .title {
    width: 100px;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .desc {
    color: #99A3A3;
    font-size: 14px;
    margin-top: 8px;
  }
}
.operate-row {
  display: flex;
  align-items: center;
  .btn-operate {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-left: 20px;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
}
.btn-add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90px;
  text-align: center;
  font-size: 16px;
  color: var(--theme-color);
  background-color: #fff;
  img {
    width: 35px;
    height: 35px;
    margin-bottom: 10px;
  }
}
</style>